@import url('https://use.typekit.net/iyd2nnq.css');

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:lang(zh),
*:lang(tw) {
  word-break: break-all;
}

body {
  word-break: keep-all !important;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  border-radius: 5px;
  -webkit-text-fill-color: #191816;
  -webkit-box-shadow: 0 0 0px 1000px #fefcf800 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.zh-keepWord:lang(zh),
.zh-keepWord:lang(tw) {
  word-break: keep-all;
}

.weglot-container {
  outline: 1px solid #3d3b38;
  border-radius: 5px;
  font-size: 16px !important;
  cursor: pointer;
  z-index: 1000 !important;
  background-color: #fefcf8;
}

.weglot-container a,
.weglot-container a:hover {
  color: #3d3b38 !important;
}
.weglot-container ul a:focus {
  background-color: #d8e5c5 !important;
  width: 100% !important;
}

.country-selector .wg-li {
  font-size: 16px !important;
}

.wg-drop.country-selector {
  background-color: transparent !important;
}

.wg-drop.country-selector ul {
  background: #fefcf8 !important;
  border: none !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  top: 38px !important;
}

.wg-drop.weg-openup ul {
  bottom: unset !important;
  right: 1px;
}

@media only screen and (min-width: 375px) {
  .wg-drop.weg-openup ul {
    right: unset;
  }
}

@media only screen and (max-width: 374px) {
  .wg-drop.weg-openup ul {
    right: unset;
  }
}

.wg-drop.country-selector li:hover {
  background-color: #d8e5c5;
}

.wgcurrent {
  border: none !important;
}

.wgcurrent::after {
  top: 1px !important;
}

.country-selector.closed .wgcurrent::after {
  -webkit-transform: unset !important;
          transform: unset !important;
}

.country-selector.open .wgcurrent::after {
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important;
}

.screenreader-text {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.screenreader-text:focus {
  position: static;
  width: auto;
  height: auto;
}

[data-style='word-break'] {
  word-wrap: break-word;
}

@media screen and (max-width: 375px) {
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

@media print {
  header,
  #appbar,
  footer {
    display: none;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #fcf8ed;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Job Listings Module CSS Overrides */
.cc-careers-container.search-top {
  gap: 24px;
}

.cc-filters-container {
  /* margin-bottom: 40px; */
  background-color: unset;
  padding: unset;
}

.cc-careers-container .cc-portal-share-container {
  margin-bottom: unset;
}

/* Job Search Container */
.cc-careers-container.search-top .cc-filters-container {
  display: flex;
  gap: 24px;
}

/* Search Bar */
.cc-filters-container .cc-keywords-wrapper {
  height: unset;
  flex: 1 1;
}

.cc-careers-container input {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #1f4d25;
  background: #fefcf8;
  color: #3d3b38;
  font-family: tablet-gothic;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  font-display: swap;
}

.cc-filters-container .cc-keywords-wrapper::before {
  content: url('/assets/job-listings/search.svg');
}

/* Filter Group */
.cc-filters-container .cc-filter-group {
  flex-flow: unset;
  gap: 24px;
}

/* Filter Group Dropdown */
.cc-filters-container .cc-filter-group .cc-filter-dropdown {
  display: unset;
  width: 265px;
  border-radius: 5px;
  border: 1px solid #3d3b38;
  background: #fefcf8;
  position: relative;
}

.cc-careers-container .cc-custom-select-trigger {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  align-items: center;
  margin: unset;
}

.cc-careers-container .cc-custom-select-trigger .cc-custom-span {
  color: #3d3b38;
  font-family: tablet-gothic;
  font-size: 18px;
  line-height: 130%;
  padding-right: 32px;
  font-display: swap;
}

.cc-careers-container .cc-custom-select-trigger .cc-custom-span::before {
  content: url('/assets/job-listings/chevron-down.svg');
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-45%);
          transform: translateY(-45%);
}

.cc-careers-container
  .cc-filter-dropdown.custom.isActive
  .cc-custom-select-trigger
  .cc-custom-span::before {
  content: url('/assets/job-listings/chevron-up.svg');
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-45%);
          transform: translateY(-45%);
}

.cc-careers-container .cc-custom-select-trigger .cc-custom-trigger-arrow {
  display: none;
}

.cc-careers-container .cc-filter-dropdown.native {
  display: none;
  width: 265px;
  border-radius: 5px;
  border: 1px solid #3d3b38;
  background: #fefcf8;
  position: relative;
  outline: unset;
}

/* Select List */
.cc-careers-container .cc-custom-select-options {
  border-radius: 5px;
}

.cc-careers-container .cc-filter-dropdown.custom.isActive {
  border-radius: 5px;
}

.cc-careers-container .cc-custom-option {
  color: #3d3b38;
  font-family: tablet-gothic;
  font-size: 18px;
  line-height: 130%;
  padding: 16px 20px;
  font-display: swap;
}

.cc-careers-container .cc-custom-option:hover,
.cc-careers-container .cc-custom-option.isHover {
  cursor: pointer;
  background-color: #d8e5c5;
  color: #3d3b38;
}

.cc-careers-container .cc-custom-option.isActive {
  background-color: #517843;
  color: #fefcf8;
}

/* Filter Group Buttons */
.cc-filters-container {
  min-width: unset;
  color: #3d3b38;
  font-family: tablet-gothic;
  font-size: 18px;
  line-height: 130%;
  background: #fefcf8;
  font-display: swap;
}

.cc-filters-container .cc-search-button-container {
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
}

.cc-careers-container button {
  display: flex;
  min-height: 40px;
  height: 40px;
  padding: 11px 22px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 25px;
  background: #1f4d25;
  color: #fefcf8;
  text-align: center;
  font-family: roc-grotesk-condensed;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  font-display: swap;
}

.cc-filters-container
  .cc-search-button-container
  .cc-submit-search-button:hover {
  background-color: #517843;
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.16)';
}

.cc-filters-container .cc-search-button-container .cc-reset-search-button {
  margin: unset;
  min-height: 40px;
  height: 40px;
  display: flex;
  padding: 11px 8px 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #1f4d25;
  text-align: center;
  font-family: roc-grotesk-condensed;
  font-display: swap;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.cc-filters-container
  .cc-search-button-container
  .cc-reset-search-button:hover {
  color: #517843;
}

/* Social Media sharing and job listing text content */
.cc-job-portal-link-text {
  color: #191816;
  font-family: tablet-gothic;
  font-display: swap;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.cc-share-container {
  gap: 15px;
}

.cc-share-container .cc-share-link,
.cc-share-container .cc-copy-btn {
  background-color: #1f4d25;
  border-radius: 50%;
  min-height: 32px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cc-share-container .cc-copy-btn .cc-share-logo {
  font-size: unset;
}

.cc-share-container .cc-share-logo {
  font-size: unset;
  height: 16px;
  width: 16px;
}

#cc-facebook-logo-id {
  height: 20px;
  width: 20px;
}

.cc-share-header-text {
  color: #191816;
  font-family: roc-grotesk-condensed;
  font-display: swap;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  font-display: swap;
}

.cc-job-portal-link {
  color: #1f4d25;
  font-family: tablet-gothic;
  font-display: swap;
  font-size: 18px;
  line-height: 130%;
  position: relative;
  text-decoration: none;
  background: linear-gradient(currentColor, currentColor) right bottom 1px
    no-repeat;
  background-size: 100% 1px;
  transition: all 0.5s;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.cc-job-portal-link:hover {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: changeWidth;
          animation-name: changeWidth;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes changeWidth {
  0% {
    background: linear-gradient(currentColor, currentColor) right bottom 1px
      no-repeat;
    background-size: 50% 1px;
  }
  50% {
    background: linear-gradient(currentColor, currentColor) right bottom 1px
      no-repeat;
    background-size: 0% 1px;
  }
  55% {
    background: linear-gradient(currentColor, currentColor) left bottom 1px
      no-repeat;
    background-size: 0% 1px;
  }
  100% {
    background: linear-gradient(currentColor, currentColor) left bottom 1px
      no-repeat;
    background-size: 100% 1px;
  }
}

@keyframes changeWidth {
  0% {
    background: linear-gradient(currentColor, currentColor) right bottom 1px
      no-repeat;
    background-size: 50% 1px;
  }
  50% {
    background: linear-gradient(currentColor, currentColor) right bottom 1px
      no-repeat;
    background-size: 0% 1px;
  }
  55% {
    background: linear-gradient(currentColor, currentColor) left bottom 1px
      no-repeat;
    background-size: 0% 1px;
  }
  100% {
    background: linear-gradient(currentColor, currentColor) left bottom 1px
      no-repeat;
    background-size: 100% 1px;
  }
}

.cc-jobs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 40px;
  width: 45%;
}

.cc-job-container {
  position: relative;
  width: 100%;
  margin-bottom: unset;
  padding: 16px 0;
  border-bottom: 1px solid #d0cdc6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0;
}

.cc-job-container::before {
  content: url('/assets/job-listings/chevron-right.svg');
  position: absolute;
  top: 20px;
  right: 0;
}

.cc-job-title {
  color: #1f4d25;
  font-family: mandrel-condensed;
  font-display: swap;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 105%;
  letter-spacing: -0.64px;
  margin-right: 40px;
}

.cc-job-title:hover {
  color: #517843;
}

.cc-location-label,
.cc-date-label {
  color: #3d3b38;
  font-family: tablet-gothic;
  font-display: swap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-right: 40px;
}

.cc-secondary-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0;
}

/* No results Message */
.cc-empty-state-message {
  color: #191816;
  font-family: tablet-gothic;
  font-display: swap;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-align: left;
}

/* Job Page Styles */

.cc-job-description-container {
  margin: unset;
  gap: 8px;
  width: 79.33%;
}

/* Back Button */
.cc-job-description-container .cc-back-to-jobs-button {
  margin: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #191816;
  font-family: tablet-gothic;
  font-display: swap;
  font-size: 18px;
  line-height: 130%;
  padding: 64px 157px 0;
}

.cc-job-description-container .cc-back-to-jobs-button img {
  height: 24px;
  width: 24px;
}

/* Job Title */
.cc-job-description-title {
  margin-top: 16px;
  color: #1f4d25;
  font-family: mandrel-condensed;
  font-display: swap;
  font-size: 32px;
  font-weight: 600;
  line-height: 105%;
  letter-spacing: -0.64px;
  padding: 0 157px;
}

.cc-job-description-container .cc-job-description-secondary-label {
  color: #3d3b38;
  font-family: tablet-gothic;
  font-display: swap;
  font-size: 16px;
  line-height: 130%;
  padding: 0 157px;
}

/* Share container */
.cc-job-description-container .cc-apply-share-container {
  margin: unset;
  margin-top: 16px;
  padding: 0 157px;
}

.cc-job-description-container .cc-apply-button {
  display: flex;
  min-height: 40px;
  height: 40px;
  padding: 11px 32px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 25px;
  background: #1f4d25;
  color: #fefcf8;
  text-align: center;
  font-family: roc-grotesk-condensed;
  font-display: swap;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.cc-job-description-container .cc-apply-button:last-child {
  margin: 0 157px 64px;
}

.cc-job-description-container .cc-apply-button:hover {
  background-color: #517843;
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.16)';
}

.cc-job-description-container .cc-job-description-text {
  margin: unset;
  background-color: #fefcf8;
  padding: 64px 0 32px 0;
  padding: 64px 157px;
}

.cc-job-description-container .cc-job-description-text h2 {
  margin: 0;
  color: #1f4d25;
  font-family: mandrel-condensed;
  font-display: swap;
  font-size: 32px;
  font-weight: 600;
  line-height: 105%;
  letter-spacing: -0.64px;
}

.cc-job-description-container .cc-job-description-text p {
  color: #1f4d25;
  font-family: tablet-gothic;
  font-display: swap;
  font-size: 16px;
  line-height: 130%;
}

.cc-job-description-container .cc-job-description-text h3 {
  color: #1f4d25;
  font-family: roc-grotesk-condensed;
  font-display: swap;
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  margin-top: 32px;
  margin-bottom: 8px;
}

.cc-job-description-container .cc-job-description-text ul {
  margin: 0;
}

.cc-job-description-container .cc-job-description-text li {
  color: #1f4d25;
  font-family: tablet-gothic;
  font-display: swap;
  font-size: 16px;
  line-height: 130%;
}

.cc-job-description-container .cc-job-description-text li::marker {
  color: #1f4d25;
}

.cc-job-description-container .cc-job-description-text a {
  color: #1f4d25;
  font-family: tablet-gothic;
  font-display: swap;
  position: relative;
  text-decoration: none;
  background: linear-gradient(currentColor, currentColor) right bottom 1px
    no-repeat;
  background-size: 100% 1px;
  transition: all 0.5s;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.cc-job-description-container .cc-job-description-text a:hover,
.cc-job-description-container .cc-job-description-text a:active,
.cc-job-description-container .cc-job-description-text a:visited {
  color: #1f4d25;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: changeWidth;
          animation-name: changeWidth;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@media screen and (max-width: 766px) {
  .cc-job-description-container {
    width: 100%;
  }

  .cc-job-description-container .cc-job-description-mobile-border::after {
    border-bottom: unset;
  }

  .cc-job-description-container .cc-apply-share-container {
    gap: 40px;
    flex-direction: column;
  }

  .cc-careers-container.search-top .cc-filters-container {
    flex-direction: column;
    gap: 16px;
  }

  .cc-filters-container .cc-filter-group {
    flex-direction: column;
    gap: 16px;
  }

  .cc-filters-container .cc-filter-group .cc-filter-dropdown {
    width: 100%;
  }

  .cc-filters-container .cc-search-button-container {
    flex-direction: column;
    gap: 16px;
  }

  .cc-filters-container .cc-search-button-container button {
    width: 100%;
  }

  /* Individual Job Container */
  .cc-jobs-container {
    width: 100%;
  }

  /* Share container */
  .cc-careers-container .cc-portal-share-container {
    flex-flow: column;
    gap: 24px;
  }

  /* Paddings for the entire module */
  .cc-job-description-container .cc-back-to-jobs-button {
    padding: 24px 24px 16px;
  }

  .cc-job-description-title,
  .cc-job-description-container .cc-job-description-secondary-label,
  .cc-job-description-container .cc-apply-share-container {
    padding: 0 24px;
  }

  .cc-job-description-container .cc-job-description-text {
    padding: 32px 24px 24px;
  }

  .cc-job-description-container .cc-apply-button:last-child {
    margin: 0 24px 40px;
  }

  .cc-careers-container.search-top {
    padding: 0px 24px;
  }

  .cc-job-description-container .cc-share-header-text {
    display: unset;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  .cc-careers-container.search-top .cc-filters-container {
    flex-direction: column;
    gap: 16px;
  }

  .cc-filters-container .cc-filter-group .cc-filter-dropdown {
    width: 100%;
  }

  .cc-job-description-container {
    width: 100%;
  }

  /* Individual Job Container */
  .cc-jobs-container {
    width: 100%;
  }

  .cc-filters-container .cc-filter-group {
    flex-direction: column;
    gap: 16px;
  }

  .cc-filters-container .cc-search-button-container {
    flex-direction: column;
    gap: 16px;
  }

  .cc-filters-container .cc-search-button-container button {
    width: 100%;
  }

  .cc-share-container .cc-share-header-text {
    display: unset;
  }

  .cc-job-description-container .cc-back-to-jobs-button {
    padding: 24px 40px 0px;
  }

  .cc-job-description-title,
  .cc-job-description-container .cc-job-description-secondary-label,
  .cc-job-description-container .cc-apply-share-container {
    padding: 0 40px;
  }

  .cc-job-description-container .cc-job-description-text {
    padding: 32px 40px 24px;
  }

  .cc-job-description-container .cc-apply-button:last-child {
    margin: 0 40px 40px;
  }

  .cc-careers-container.search-top {
    padding: 0px 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .cc-job-description-container .cc-back-to-jobs-button {
    padding: 24px 40px 16px;
  }

  .cc-job-description-title,
  .cc-job-description-container .cc-job-description-secondary-label,
  .cc-job-description-container .cc-apply-share-container {
    padding: 0 40px;
  }

  .cc-job-description-container .cc-job-description-text {
    padding: 56px 40px 24px;
  }

  .cc-job-description-container .cc-apply-button:last-child {
    margin: 0 40px 64px;
  }

  .cc-careers-container.search-top {
    padding: 0px 40px;
  }

  /* Individual Job Container */
  .cc-jobs-container {
    width: 45%;
  }
}

@media screen and (min-width: 1440px) {
  .cc-job-description-container .cc-back-to-jobs-button {
    padding: 40px 157px 16px;
  }

  .cc-job-description-title,
  .cc-job-description-container .cc-job-description-secondary-label,
  .cc-job-description-container .cc-apply-share-container {
    padding: 0 157px;
  }

  .cc-job-description-container .cc-job-description-text {
    padding: 56px 157px 24px;
  }

  .cc-job-description-container .cc-apply-button:last-child {
    margin: 0 157px 64px;
  }

  .cc-careers-container.search-top {
    padding: 0px 157px;
  }

  .cc-jobs-container {
    margin-bottom: 64px;
  }

  /* Individual Job Container */
  .cc-jobs-container {
    width: 45%;
  }
}

/* Start CSS for Date-Picker  */
.rdrDateRangePickerWrapper {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}

.rdrDateRangePickerWrapper .rdrCalendarWrapper {
  background-color: #fcf8ed;
  width: -webkit-fill-available;
  margin-top: 10px;
}

.rdrDateRangePickerWrapper .rdrCalendarWrapper .rdrMonths {
  background-color: #fefcf8;
  padding: 10px;
  border-left: 2px solid #f9f1db;
  border-right: 2px solid #f9f1db;
  justify-content: center;
}
.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  width: -webkit-fill-available;
  border: 1px solid #fefcf8;
  height: 156px;
  border-radius: 8px;
}
.rdrDateRangePickerWrapper .rdrStaticRanges {
  width: -webkit-fill-available;
}
.rdrDateRangePickerWrapper .rdrStaticRanges button {
  background-color: #fefcf8;
}
.rdrDateRangePickerWrapper .rdrInputRanges {
  display: none;
}
.custom-chip {
  border-radius: 16px;
}
.rdrStartEdge:active .rdrEndEdge:active {
  background-color: pink !important;
}

.rdrMonthAndYearWrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0px 16px !important;
  border-left: 2px solid #f9f1db;
  border-right: 2px solid #f9f1db;
}

.rdrNextPrevButton {
  order: 3 !important;
  background: none !important ;
}

.rdrMonthButton {
  order: 2 !important;
}

.rdrYearButton {
  order: 1;
}

.rdrStaticRangeLabel {
  padding: 0px !important;
}
.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: none !important;
}

.rdrStaticRange {
  border-bottom: none !important;
}

.rdrMonthAndYearPickers {
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 20.8px;
  text-align: center;
  color: #3d3b38;
  font-family: 'tablet-gothic';
  order: 1 !important;
  justify-content: start !important;
}

.rdrMonthName {
  display: none;
}
.rdrWeekDay {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 20.8px !important;
  color: #3d3b38 !important;
  font-family: 'tablet-gothic';
}

.rdrDayToday .rdrDayNumber span {
  color: #1f4d25 !important;
}

.rdrDayNumber span {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #3d3b38 !important;
  font-family: 'tablet-gothic';
  z-index: 1;
}

.rdrStaticRanges {
  justify-content: center;
  flex-direction: row !important;
  gap: 10px;
  padding: 16px 26px;
}

.rdrMonth {
  width: 100% !important;
  padding: 0px 6px !important;
}

.rdrDay {
  height: 60px !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: none !important;
}

.rdrNextButton i {
  width: 10px !important;
  height: 10px !important;
  border: solid #3d3b38 !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  -webkit-transform: rotate(-45deg) !important;
          transform: rotate(-45deg) !important;
}

.rdrPprevButton i {
  width: 10px !important;
  height: 10px !important;
  border: solid #3d3b38 !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  -webkit-transform: rotate(135deg) !important;
          transform: rotate(135deg) !important;
}

.rdrPprevButton.rdrNextButton {
  border: 2px solid #191816;
}

.rdrDay.rdrDayToday span.rdrDayNumber {
  border: 1px solid #1f4d25 !important;
  background: none !important;
  border-radius: 50% !important;
  font-family: 'tablet-gothic';
  width: 48.5px;
  margin-left: 2.5px;
}

.rdrInRange {
  background-color: #bfd89e !important;
  border-radius: 0px !important;
  border-top: 1px solid #1f4d25;
  border-bottom: 1px solid #1f4d25;
}

.rdrStartEdge {
  background-color: #bfd89e !important;
  border-radius: 50% 0 0 50% !important;
  color: #ffffff !important;
  border: 1px solid #1f4d25;
  border-right: 0px;
}

.rdrEndEdge {
  background-color: #bfd89e !important;
  border-radius: 0 50% 50% 0 !important;
  color: #ffffff !important;
  border: 1px solid #1f4d25;
  border-left: 0px;
}

.rdrStartEdge.rdrEndEdge {
  background-color: #bfd89e !important;
  color: #ffffff !important;
  border-radius: 50% !important;
  border: 1px solid #1f4d25;
}

.rdrDaySelected {
  background-color: #e3f2fd !important;
  border-radius: 50% !important;
}

.rdrDay.rdrDayToday .rdrDayNumber .rdrStartEdge.rdrEndEdge {
  background: none !important;
  color: transparent !important;
}

.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-radius: 50% !important;
}

.rdrDayDisabled {
  background: none !important;
  opacity: 38%;
}

.rdrDayPassive {
  visibility: hidden !important;
}

.rdrDayStartPreview {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  border: 0px !important;
  background: #d8e5c5 !important;
  z-index: 0;
}

.rdrDayEndPreview {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  border: 0px !important;
  background: #d8e5c5 !important;
  z-index: 0;
}

.rdrDayInPreview {
  border: 0px !important;
  background: #d8e5c5 !important;
  z-index: 0;
}

.rdrDayEndOfWeek .rdrDayInPreview {
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.rdrDayStartOfWeek .rdrDayInPreview {
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
}
.rdrDayEndOfWeek .rdrInRange {
  border-right: 1px solid #1f4d25;
}

.rdrDayStartOfWeek .rdrInRange {
  border-left: 1px solid #1f4d25;
}

.rdrDayStartOfMonth .rdrInRange {
  border-left: 1px solid #1f4d25;
}

.rdrDayEndOfMonth .rdrInRange {
  border-right: 1px solid #1f4d25;
}

.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rdrDayEndOfMonth .rdrDayInPreview {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rdrDayStartOfMonth .rdrDayInPreview {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-left: 1px solid #1f4d25;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-right: 1px solid #1f4d25;
}

.css-wej524-selectionContainer {
  pointer-events: none;
}

@media screen and (max-width: 719px) {
  .rdrDay.rdrDayToday span.rdrDayNumber {
    width: 49px;
  }
}

@media screen and (max-width: 448px) {
  .rdrDay.rdrDayToday span.rdrDayNumber {
    width: auto;
    margin: 0px 2px;
  }
  .rdrDay {
    height: 56px !important;
  }
}

@media screen and (max-width: 428px) {
  .rdrDay {
    height: 54px !important;
  }
}

@media screen and (max-width: 408px) {
  .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    max-height: 170px;
  }
  .rdrDay {
    height: 52px !important;
  }
}

@media screen and (max-width: 388px) {
  .rdrDay {
    height: 48px !important;
  }
}

@media screen and (max-width: 378px) {
  .rdrDay {
    height: 48px !important;
  }
}
@media screen and (max-width: 358px) {
  .rdrDay {
    height: 44px !important;
  }
}

@media screen and (max-width: 328px) {
  .rdrDay {
    height: 42px !important;
  }
}

/* End CSS for Date-Picker  */

